.callback {

  color: white;

  & > span, &__agreement{
    width: 47%;
  }

  &__desc, &__phone, &__email {

    font-size: em(45px);
    margin-bottom: em(40px, 45px);
  }

  &__phone {
    margin-bottom: em(4px, 38px);
  }

}