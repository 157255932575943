.button {

  &--wrapper {
    margin-top: 0;
    position: relative;
    top: 1.33em;
  }

  &--wrapper & {
    width: auto;
  }

  &_diff {
    padding: 1em 2em;
  }

  &_diff-1 {
    white-space: nowrap;
  }


}