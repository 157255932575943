.product-menu {

  &__head {
    &::after {
      margin: 1.5em 0 0;
    }
  }

  &__body {
    .wrapper > & {
      margin-bottom: em(-40px);
    }
  }

  &__list {
    width: 25%;
  }
}