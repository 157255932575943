.reviews {

  &__row {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  &__year, &__cell {
    display: table-cell;
    vertical-align: top;
  }

  &__year {
    padding-right: em(18px, 40px);
    width: em(97px, 40px);
  }

  &__cell {
    padding-left: em(36px);
    padding-top: em(11px);
    width: 100%;
  }

  &__item {
    margin-bottom: em(35px);
  }

}