.menu {
  &__search {
    width: em(325px);
    input[type=text] {
      width: 90%;
    }
  }
  &__loupe {
    width: 10%;
  }
}