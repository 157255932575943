.search-result {

  &__item {
    margin-bottom: 0;
    text-align: justify;
    white-space: normal;
    word-spacing: -.3em;
    &::after {
      content: "";
      display: inline-block;
      width: 100%;
    }
  }

  &__info {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    width: 60%;
  }

  &__control {
    display: inline-block;
    margin: 2em 0;
    vertical-align: middle;
    width: 36%;
  }

  &__label {
    padding-bottom: em(10px);
    padding-top: em(10px);
  }

  &__button {
    font-size: em(12px);
  }

}