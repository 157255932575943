.company {


  &__item {
    margin: 1% .5% 1%;
    width: auto;
  }

  &__wrapper {
    padding: 0 0;
  }

}