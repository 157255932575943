.configurator {


  &__image {
    left: 15%;
    width: 70%;
    &_small img{
      max-height: em(90px);
    }
  }

  &__title {
    font-size: em(24px);
  }

  &__list, &__item:last-child &__list {
    bottom: em(-140px);
    left: 0;
    right: 0;
    top: em(-90px);
  }

}