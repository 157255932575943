.ticket {
  direction: rtl;
  display: table;
  margin-bottom: 2em;
  table-layout: fixed;
  width: 100%;

  &__item {
    direction: ltr;
    display: table-cell;
    vertical-align: bottom;
    width: 50%;
    &:first-child {
      padding-left: em(20px);
    }
    &:last-child {
      padding-right: em(20px);
    }
  }

  &__info {
    border-bottom: em(2px) solid $_pale;
  }

  &__gear {

  }

  &__button.button {
  }


}