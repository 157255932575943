.banner {
  height: em(606px);

  .wrapper {

    &::after {

    }
  }

  &__background {


  }

  &__logo {

  }

  &__content {

  }

  &__button {

  }


  &__hpe {

    &_logo {

    }

    &_text {
      left: 33%;
    }

    &_top {
      font-size: em(48px, 26px);
    }
    &_middle, &_bottom {
      display: inline;
      font-size: em(90px, 26px);
    }
    &_bottom {
      margin-left: .2em;
    }
  }






}