.wysiwyg {


  ul li {

  }

  .blue {

  }

  h2 {
    clear: both;
    font-size: em(40px);
    margin-bottom: em(17px, 40px);
  }

  h3 {
    clear: both;
    font-size: em(30px);
    margin-bottom: em(17px, 30px);
  }

  * + h2 {
    margin-top: em(30px, 40px);
  }

  * + h3 {
    margin-top: em(40px, 30px);
  }

  .button {
    min-width: 0;
  }

  ol {

  }

  ol ol {

  }

  ol ol ol {

  }

  ol li {

  }

  ol ol li {

  }

  ol ol ol li {

  }

  a:not([class]) {

  }

  table {

    th, td  {

    }
    th {

    }
    td {

    }
    tr:nth-child(even) td {

    }
  }

  form:not([class]) {

    text-align: justify;
    word-spacing: -.3em;

    &::after {
      content: "";
      display: inline-block;
      width: 100%;
    }

    label {
      display: inline-block;
      text-align: left;
      vertical-align: top;
      width: 48%;
      word-spacing: 0;

      & > span {
        &::before {
          clear: both;
          content: "";
          display: block;
        }
        display: block;
      }
    }

    input, select, textarea {

    }
    textarea {

    }
    *[type="submit"] {
      margin: em(24px) auto 0;
    }
    .captcha {
      margin: em(24px) auto 0;
      img {
        float: left;
        margin-bottom: 0;

      }
      input {
        float: right;
        width: 60%;
      }
    }
  }

  &__service-button {
    margin-bottom: em(33px);
    margin-top: em(-33px);
    text-align: right;
  }

}
