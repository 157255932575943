.project {

  &__item {
    text-align: left;
    margin-bottom: em(46px);
    &::after {
      clear: both;
      content: "";
      display: block;
    }
  }

  &__image {
    display: block;
    float: left;
    height: em(261px);
    margin-top: .3em;
    width: 48%;
  }

  &__title {
    display: block;
    font-size: em(24px);
    line-height: 1.2em;
    margin-left: 52%;
  }

  &__desc {
    clear: none;
    display: block;
    margin-left: 52%;
    width: auto;
  }

  &__helper {
    clear: none;
    display: block;
    margin-left: 52%;
  }


}
