.feature {


  &__menu {
    border:none;
    margin-left: em(-8px);
    margin-right: em(-8px);
    &::after {
      background: $_marin;
      bottom: 0;
      content: "";
      height: em(2px);
      left: em(8px);
      position: absolute;
      right: em(8px);
      top: auto;
      transform: none;
      width: auto;
    }
  }

  &__wrapper {
    //border-bottom: em(2px) solid $_marin;
    border-spacing: em(8px) 0;
    display: table;
    width: 100%;
  }

  &__menu_open &__tab.active,
  &__menu_open &__tab:hover,
  &__menu_open &__tab, &__tab, &__tab.active {
    display: table-cell;
  }


  &__menu &__tab.active {
    border-color: $_marin;
    color: $_marin;
  }

  &__tab_container {
    color: $_marin;
    position: relative;
  }

  &__container {
    background: white;
    border: em(2px) solid $_gray-middle;
    display: none;
    position: absolute;
    right: em(-2px);
    text-align: right;
    top: em(-2px);
  }

  &__container.open {
    display: block;
  }

  &__menu &__tab_container &__tab {
    border: none;
    display: block;
    margin: 0;
    padding: 1em;
    &:hover {
      background: $_gray-light
    }
  }



}