.lt-desktop,
span.lt-desktop,
br.lt-desktop
{
  display: none;
}

span.e-tablet, br.e-tablet, .e-tablet {
  display: none;
}

.gt-tablet {
  display: block;
}

span.gt-tablet, a.gt-tablet, br.gt-tablet {
  display: inline-block;
}