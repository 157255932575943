.footer {

  /*.wrapper & {
    margin-bottom: -1.33em;
  }*/

  &__logo {
    vertical-align: middle;
  }

  &__address, &__phone {
    display: inline-block;
    vertical-align: middle;
  }
  &__phone {
    margin-right: 1em;
  }

  &__contact {
    vertical-align: middle;
  }

  &__copyright {
    vertical-align: middle;
  }
}