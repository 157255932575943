.popup-form {

  min-width: em(700px);
  width: 50%;

  &::after {

  }

  &--wrapper {


    &::after {

    }
  }

  &__title {

  }

  &__row {
    &_wide {
      width: 100%;
      text-align: justify;
      &::after {
        content: "";
        display: inline-block;
        width: 100%;
      }
    }
    &_left {
      text-align: left;
    }
  }

  .result__line {
    display: inline-block;
    vertical-align: top;
    width: 48%;
  }


  .button {
    display: block;
    margin: 1em auto 0;
    width: 70%;
  }



}