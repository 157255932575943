.config-form {


  white-space: nowrap;

  &__options {
    display: inline-block;
    padding-right: 1%;
    vertical-align: top;
    width: 65%;
    white-space: normal;

    &_wide {
      display: block;
      padding-right: 0;
      width: 100%;
    }
  }


  &__row {
    margin-bottom: 0;
    &_main {
      min-height: em(340px);
    }
  }


  &__text {
    clear: both;
  }

  &::after {
    clear: both;
    content: "";
    display: block;
  }



}