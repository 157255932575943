.stock {

  &__content {
    padding-right: em(230px);
  }

  &__button {

    margin-left: em(-200px);

  }
}