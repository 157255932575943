@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@function em($pixels, $c: $font-size) {
  @if type-of($pixels) != 'number' {
    @error $pixels;
  }
  @else if unit($pixels) == em {
    @return $pixels;
  }
  @return $pixels/$c * 1em
}

@mixin arrow-up($color, $size: 10px, $width: 2px) {
  border-bottom: none;
  border-left: $width solid $color;
  border-right: none;
  border-top: $width solid $color;
  display: inline-block;
  height: $size;
  transform: rotate(45deg);
  width: $size;
}

@mixin arrow-down($color, $size: 10px, $width: 2px) {
  border-bottom: $width solid $color;
  border-left: none;
  border-right: $width solid $color;
  border-top: none;
  display: inline-block;
  height: $size;
  transform: rotate(45deg);
  width: $size;
}

@mixin arrow-right($color, $size: 10px, $width: 2px) {
  border-bottom: none;
  border-left: none;
  border-right: $width solid $color;
  border-top: $width solid $color;
  display: inline-block;
  height: $size;
  transform: rotate(45deg);
  width: $size;
}

@mixin arrow-left($color, $size: 10px, $width: 2px) {
  border-bottom: $width solid $color;
  border-left: $width solid $color;
  border-right: none;
  border-top: none;
  display: inline-block;
  height: $size;
  transform: rotate(45deg);
  width: $size;
}

@mixin cross($color, $size: 100%, $width: 30%) {
  &::before, &::after {
    background: $color;
    content:"";
    display: block;
    height: $width;
    margin-top: -($width/2);
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: $size;
  }
  &::after {
    transform: rotate(-45deg);
  }
}
@mixin plus($color, $size: 100%, $width: 30%) {
  &::before {
    background: $color;
    content:"";
    display: block;
    height: $width;
    margin-top: -($width/2);
    position: absolute;
    top: 50%;
    width: $size;
  }
  &::after {
    background: $color;
    content:"";
    display: block;
    height: $size;
    left: 50%;
    margin-left: -($width/2);
    position: absolute;
    width: $width;
  }
}


@mixin loupe ($color, $radius: $indent, $handle: $radius/2, $line: $radius/10) {

  overflow: visible;
  position: relative;

  //@error $radius;

  &::before {
    border: em($line) solid $color;
    border-radius: 50%;
    content: "";
    display: block;
    left: 50%;
    margin-left: em(-$radius)/2;
    height: em($radius);
    position: absolute;
    top: 0;
    width: em($radius);
  }
  &::after {
    background: $color;
    border-radius: $line;
    content: "";
    display: block;
    left: 50%;
    margin-left: em($radius)/3.5;
    position: absolute;
    top: em($radius)*.8;
    width: em($handle);
    height: em($line);
    transform: rotate(45deg);
    transform-origin: 0 0;
  }
}



@mixin imagine($axis:'x') {
  & {
    overflow: hidden;
    position: relative;
    & > img {
      position: absolute;
      height: auto;
      margin: auto;
      min-height: 100%;
      min-width: 100%;
      width: auto;
      @if ($axis == 'x') {
        bottom: -200%;
        left: 0;
        right: 0;
        top: -200%;
        width: 100%;
      } @else {
        bottom: 0;
        height: 100%;
        left: -200%;
        right: -200%;
        top: 0;
      }
    }
  }
}

@mixin column-view($parentWidth, $childIndent, $childCount, $toEm:false) {
  & {
    // ((s-(c-1)*o)*100)/c*(s+o);
    // NOTE: this mixin break layout, if in ascending structure we have display: table
    // need to add to display:table rule "table-layout: fixed"
    $width: (($parentWidth - ($childCount - 1) * $childIndent) * 100) / ($childCount * ($parentWidth + $childIndent + 4));
    display: flex;
    flex-wrap: wrap;
    @if($toEm) {
      margin-right: em(-$childIndent);
    }
    @else {
      margin-right: -#{strip-unit($childIndent)}px;
    }
    word-spacing: -.3em;
    & > * {
      display: inline-block;
      @if($toEm) {
        margin-right: em($childIndent);
      }
      @else {
        margin-right: #{strip-unit($childIndent)}px;
      }
      width: $width*1%;
      max-width: $width*1%;
      min-width: $width*1%;
      word-spacing: 0;
    }
  }
}