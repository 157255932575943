.map {

  white-space: nowrap;

  word-spacing: -.3em;

  &__desc {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
    white-space: normal;
    width: 50%;
    &:nth-child(odd) {
      padding-right: em(15px);
    }
    &:nth-child(even) {
      padding-left: em(15px);
    }
  }

}