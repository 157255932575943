.char {

  &__family {
    width: 25%;
  }

  &__property {
    width: 35%;
  }

  &__value {
    width: 65%;
  }

}