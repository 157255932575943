.result {

  display: inline-block;
  margin-bottom: 0;
  vertical-align: top;
  width: 30%;
  white-space: normal;
  &::after {
    height: em(296px);
  }

  &_fixed {
    transition: margin .2s;
  }

  &__text {
    display: inline-block;
  }

  &__item {
    padding-left: .5em;
    padding-right: .5em;
  }

  &__image {
    padding-top: 60%;
    width: 60%;
    &_logo {
      display: block;
      margin: .5em auto;
      padding-top: 15%;
      width: 60%;
    }
  }

  &__send.button {
    float: right;
    width: auto;
  }

  &__block {
    margin-left: 8%;
    &_manufacturer {
      margin-left: 0;
      &::after {
        height: em(326px);
      }
    }

    &_center {
      overflow: hidden;
      position: relative;
    }

  }
}