.service-inner {
  //@include column-view(998px, 30px, 2, true);


  &__content {
    padding: em(20px) em(45px) em(77px);
  }

  &__bottom {
    bottom: 0;
    height: em(77px);
    left: em(45px);
    position: absolute;
    right: em(45px);
  }

  &__helper {
    white-space: nowrap;
    &::after {
      content:"";
      display: inline-block;
      height: em(90px);
      vertical-align: middle;
    }
  }

  &__title {
    line-height: normal;
    margin-bottom: 0;
    vertical-align: middle;
    white-space: normal;
  }

}