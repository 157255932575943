.review {

  &__item {
    padding: 0 0;
  }

  &__wrapper {
    margin: 0 auto;
    padding: 0 em(60px);
    width: $media;
  }


  &__company {
    display: inline-block;
    vertical-align: top;
    width: 31%;
  }

  &__content {
    display: inline-block;
    padding-right: em(30px);
    vertical-align: top;
    width: 69%;
  }

  &__logo, &__person {
    display: inline-block;
    text-align: center;
  }

  &__arrow {
    &_left {
      left: 50%;
      margin-left: -$media/2;
    }
    &_right {
      right: 50%;
      margin-right: -$media/2;
    }
  }


}