$indent: 13px;
$media: 1024px;
$font-size: 15px;

@import "variables";
@import "tools";

body {
  font-size: $font-size;
}


@import "W:/Projects/Web/lnc/scss/1024/_banner.scss";
@import "W:/Projects/Web/lnc/scss/1024/_brand.scss";
@import "W:/Projects/Web/lnc/scss/1024/_breadcrumbs.scss";
@import "W:/Projects/Web/lnc/scss/1024/_button.scss";
@import "W:/Projects/Web/lnc/scss/1024/_callback.scss";
@import "W:/Projects/Web/lnc/scss/1024/_catalog.scss";
@import "W:/Projects/Web/lnc/scss/1024/_char.scss";
@import "W:/Projects/Web/lnc/scss/1024/_common.scss";
@import "W:/Projects/Web/lnc/scss/1024/_company.scss";
@import "W:/Projects/Web/lnc/scss/1024/_config-form.scss";
@import "W:/Projects/Web/lnc/scss/1024/_configurator.scss";
@import "W:/Projects/Web/lnc/scss/1024/_feature.scss";
@import "W:/Projects/Web/lnc/scss/1024/_footer.scss";
@import "W:/Projects/Web/lnc/scss/1024/_gallery.scss";
@import "W:/Projects/Web/lnc/scss/1024/_license.scss";
@import "W:/Projects/Web/lnc/scss/1024/_map.scss";
@import "W:/Projects/Web/lnc/scss/1024/_menu.scss";
@import "W:/Projects/Web/lnc/scss/1024/_model.scss";
@import "W:/Projects/Web/lnc/scss/1024/_news.scss";
@import "W:/Projects/Web/lnc/scss/1024/_popup-form.scss";
@import "W:/Projects/Web/lnc/scss/1024/_privacy.scss";
@import "W:/Projects/Web/lnc/scss/1024/_product-menu.scss";
@import "W:/Projects/Web/lnc/scss/1024/_project.scss";
@import "W:/Projects/Web/lnc/scss/1024/_result.scss";
@import "W:/Projects/Web/lnc/scss/1024/_review.scss";
@import "W:/Projects/Web/lnc/scss/1024/_reviews.scss";
@import "W:/Projects/Web/lnc/scss/1024/_search-result.scss";
@import "W:/Projects/Web/lnc/scss/1024/_service-inner.scss";
@import "W:/Projects/Web/lnc/scss/1024/_service.scss";
@import "W:/Projects/Web/lnc/scss/1024/_solution.scss";
@import "W:/Projects/Web/lnc/scss/1024/_stock-thin.scss";
@import "W:/Projects/Web/lnc/scss/1024/_stock.scss";
@import "W:/Projects/Web/lnc/scss/1024/_ticket.scss";
@import "W:/Projects/Web/lnc/scss/1024/_wysiwyg.scss";
