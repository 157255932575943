.service {



  &__item_small {
    width: 64%;
  }

  &__content {
    width: 73%;
  }

  &__item_small &__content {
    width: 58%;
  }

  &__header {
    font-size: em(28px);
    margin-bottom: .5em;
  }

  &__detail {

  }

  &__list--wrapper {
    display: table;
    margin-bottom: .5em;
    width: 100%;
  }

  &__list, &__list_hidden {
    display: table-cell;
    padding-right: em(13px);
    vertical-align: top;
  }

  /*&__header {
    color: $_marin;
    font-family: $openSans;
    font-size: em(22px);
    line-height: 1.3em;
    margin-bottom: .8em;
  }

  &__list {
    margin-bottom: 1em;
  }

  &__link {
    color: $_dark-blue-deep;
    display: block;
    font-size: em(14px);
    line-height: 1.2em;
    margin: .5em 0;
    &:hover {
      text-decoration: underline;
    }
  }

  &__detail {
    display: block;
    float: right;
    font-size: em(13px);
    padding: em(8px) em(15px);
  }*/

}